(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', function (e) {
  var subscriptionForm = document.getElementById('hibiscus-subscription-form');
  subscriptionForm.addEventListener('submit', function (e) {
    e.preventDefault(); // reset the form messages

    resetMessages(); // collect all the data

    var data = {
      fname: subscriptionForm.querySelector('[name="fname"]').value,
      lname: subscriptionForm.querySelector('[name="lname"]').value,
      email: subscriptionForm.querySelector('[name="email"]').value,
      nonce: subscriptionForm.querySelector('[name="nonce"]').value
    }; // validate everything

    if (!data.fname) {
      subscriptionForm.querySelector('[data-error="invalidFirstName"]').classList.add('show');
      return;
    }

    if (!data.lname) {
      subscriptionForm.querySelector('[data-error="invalidLastName"]').classList.add('show');
      return;
    }

    if (!validateEmail(data.email)) {
      subscriptionForm.querySelector('[data-error="invalidEmail"]').classList.add('show');
      return;
    } // ajax http post request


    var url = subscriptionForm.dataset.url;
    var params = new URLSearchParams(new FormData(subscriptionForm));
    subscriptionForm.querySelector('.js-form-submission').classList.add('show');
    fetch(url, {
      method: 'POST',
      body: params
    }).then(function (res) {
      return res.json();
    })["catch"](function (error) {
      resetMessages();
      subscriptionForm.querySelector('.js-form-error').classList.add('show');
    }).then(function (response) {
      resetMessages();

      if (response === 0 || response.status === 'error') {
        subscriptionForm.querySelector('.js-form-error').classList.add('show');
        return;
      }

      subscriptionForm.querySelector('.js-form-success').classList.add('show');
      subscriptionForm.reset();
    });
  });
});

function resetMessages() {
  document.querySelectorAll('.field-msg').forEach(function (f) {
    return f.classList.remove('show');
  });
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

},{}]},{},[1]);
